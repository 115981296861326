<template>
  <div class="input-file">
    <!-- 레이블 -->
    <div v-if="label && useLabel">{{ label }}</div>

    <!-- 첨부파일 추가하기 -->
    <div v-if="maxLength > files.length" class="file-add" :class="{ 'send-mode': sendMode }">
      <input
        class="file-input"
        v-if="uploadReady"
        :accept="acceptExt"
        @click.stop
        :id="uid"
        @change="onFileChange"
        type="file"
        :multiple="multiple" />

      <label
        :for="uid"
        @click.stop
        class="file-list unselect"
        :style="{ width: sendMode ? '' : '100%', border: sendMode ? '1px solid #DDDDDD' : '1px solid $main' }">
        <img class="svg-main" :src="`/static/icon/u_${sendMode ? 'plus' : 'paperclip'}.svg`" />
        <div class="body3 sub">{{ placeholder }}</div>
      </label>
      <div class="body6 sub4" style="flex: 1; width: 100%" v-if="sendMode">{{ description }}</div>
    </div>
    <div class="file-desc" v-if="description && !sendMode">{{ description }}</div>

    <!-- 추가된 첨부파일 -->
    <div v-for="(file, idx) in files" :key="`file-${idx}`" class="file-add-list">
      <!-- 썸네일 -->
      <div v-if="false">
        <!-- 이미지 -->
        <a target="_blank" :href="file.file">
          <div v-if="isImage(file.extension)" class="thumb" :style="thumbStyle(file.file)"></div>
          <!-- 이미지 아닐 땐 파일 확장자명 표시 -->
          <div v-else :style="extColor(file.extension)" class="thumb ext">{{ upper(file.extension) }}</div>
        </a>
      </div>
      <img class="svg-sub5" src="/static/icon/u_file.svg" />
      <div class="lp-file-name" :class="{ unselect: file.file.includes('http') }" @click="onFileClick(file.file)">
        {{ file.name }}
      </div>
      <div class="trash unselect" @click="onDelete(idx)">
        <i class="material-icons">close</i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'InputFile',

  mixins: [],

  props: {
    useLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExt: {
      type: String,
      default: 'image/gif, image/jpeg, image/png, .docx, .pptx, .xlsx, .hwp, .pdf, .zip',
    },
    maxLength: {
      type: Number,
      required: false,
      default: 5,
    },
    description: {
      type: String,
      required: false,
      default:
        '첨부파일은 최대 5개, 50MB 까지 등록 가능합니다.\n' +
        '파일형식은 이미지(jpg, gif, png), 오피스 문서(hwp, pdf, pptx, docx, xlsx), 압축파일(zip)만 가능합니다.',
    },
    placeholder: {
      type: String,
      required: false,
      default: '파일첨부',
    },
    sendMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  created() {
    this.uid = moment().format('SSSS');
    this.setValue();
  },

  data() {
    return {
      uid: '',
      file: undefined,
      uploadReady: true, // 초기화 용
      files: [],
      items: [],
    };
  },

  watch: {
    value(n) {
      if (n.length === 0) {
        this.files = [];
      }
      if (n.length !== this.files.length) {
        this.setValue();
      }
    },
  },

  computed: {},

  methods: {
    onFileClick(file) {
      if (file.includes('http')) {
        window.open(file, '_blank');
      }
    },
    setValue() {
      if (this.value) {
        this.value.forEach((item) => {
          if (typeof item === 'string') {
            this.files.push({
              name: item.split('/').slice(-1)[0],
              file: item,
              extension: item.split('.').pop(),
            });
          } else {
            this.files.push(item);
          }
        });
      }
    },
    onDelete(idx) {
      this.files.splice(idx, 1);
      this.items.splice(idx, 1);
      this.$emit('setValue', this.items);
      this.$emit('update:value', this.items);
    },

    upper(ext) {
      return ext.toUpperCase();
    },

    extColor(ext) {
      let deco = { backgroundColor: '' };
      switch (ext.toLowerCase()) {
        case 'hwp':
          deco.backgroundColor = '#2ebcee';
          break;
        case 'pdf':
          deco.backgroundColor = '#ef4037';
          break;
        case 'docx':
          deco.backgroundColor = '#295498';
          break;
        case 'pptx':
          deco.backgroundColor = '#d14425';
          break;
        case 'xlsx':
          deco.backgroundColor = '#207245';
          break;
      }

      return deco;
    },

    isImage(ext) {
      let isImg = false;
      let imgExt = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'tif'];
      ext = ext.toLowerCase();
      if (imgExt.indexOf(ext) > -1) isImg = true;

      return isImg;
    },
    thumbStyle(img) {
      let deco = {
        backgroundImage: `url("${img}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };

      return deco;
    },
    onFileChange(e) {
      if (this.multiple) {
        // multiple이 true일 때의 로직
        const files = Array.from(e.target.files);
        if (files.length === 0) return;

        this.uploadReady = false;
        this.$nextTick(() => {
          this.uploadReady = true;
        });

        let totalSize = this.files.reduce((sum, file) => sum + file.size, 0);

        files.forEach((file) => {
          if (totalSize + file.size > 1024 * 1024 * 50 || this.files.length + 1 > this.maxLength) {
            this.toast(`최대 50MB, ${this.maxLength}개 파일까지만 업로드 가능합니다.`);
            return;
          }

          if (this.findItem(this.files, 'name', file.name) !== undefined) {
            this.toast(`'${file.name}'은(는) 이미 업로드된 파일입니다.`);
            return;
          }

          totalSize += file.size;

          let reader = new FileReader();
          reader.onload = (f) => {
            let ext = file.name.split('.').pop();
            let file_data = f.target.result;

            this.files.push({
              name: file.name,
              file: file_data,
              size: file.size,
              extension: ext,
            });
            this.items.push(file_data);
            this.$emit('setValue', this.items);
            this.$emit('update:value', this.items);
          };
          reader.readAsDataURL(file);
        });
      } else {
        // 기존의 single file 업로드 로직
        this.file = e.target.files[0];
        if (this.file === undefined) return;

        this.uploadReady = false;
        this.$nextTick(() => {
          this.uploadReady = true;
        });

        //10MB 또는 파일 maxLength개 까지만 업로드 가능
        let totalSize = 0;
        for (let i = 0; i < this.files.length; i++) totalSize += this.files[i].size;

        if (totalSize + this.file.size > 1024 * 1024 * 50 || this.files.length + 1 > this.maxLength) {
          this.toast(`최대 50MB, ${this.maxLength}개 파일까지만 업로드 가능합니다.`);
          return;
        }

        if (this.findItem(this.files, 'name', this.file.name) !== undefined) {
          this.toast('이미 업로드 된 파일입니다.');
          return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (f) => {
          let file = this.file.name.split('.');
          let ext = file[file.length - 1];
          let file_data = f.srcElement.result;
          this.files.push({
            name: this.file.name,
            file: file_data,
            size: this.file.size,
            extension: ext,
          });
          this.items.push(file_data);
          this.$emit('setValue', this.items);
          this.$emit('update:value', this.items);
        };
      }
    },
  },
};
</script>

<style lang="stylus" type="stylus" scoped>
@import '~assets/css/lp_main'
.input-file
  display flex
  flex-direction column
  gap 8px

.send-mode
  display flex
  align-items center
  gap 8px

.file-add-list
.file-list
  background-color #ffffff
  display flex
  align-items center
  height 46px
  border-radius 8px
  overflow hidden
  padding 12px 16px
  gap 6px
  img
    width 20px
    height 20px

.file-list
  border 1px solid $main

.file-add-list
  border 1px solid $gray1
.thumb
.file-attachment
  width 44px
  height 44px

.ext
  text-align center
  color white
  background-color #064160
  line-height 44px
  font-size 0.7rem
  font-weight 100

/*.file-attachment-text
  padding-left 12px
  line-height 46px
  font-size 0.9rem
  color $sub4*/

.file-attachment
  text-align center

.file-attachment i
  line-height 46px
  font-size 1.6rem

.file-desc
  font-size 0.7rem
  color rgba(162, 174, 186, 1)
  white-space pre-line
  padding 8px 0

.lp-file-name
  color $sub1
  font-size 15px
  text-overflow ellipsis
  overflow hidden
  white-space pre
  text-align left
  flex 1

.file-input
  width 0
  height 0
  position fixed
  top -100px
  left -100px

.trash
  text-align center

.trash i
  line-height 44px
  color $sub3
  font-size 1.4rem
  &:hover
    color $main
</style>
